import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useSelector, useDispatch } from "react-redux";
import tw from '../../styles/tailwind';
import Text from '../../components/Text';
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import UbicacionIcon from '../../assets/icons/ubicacion';
//import { buscarSucursalesCercanas } from '../../store/slices/sucursales';
import { getSucursalesData } from '../../store/slices/sucursales';


/* Iconos */
import { FontAwesome } from '@expo/vector-icons';

export default function FormaPagoSucursal({ navigation }) {
    const [center, setCenter] = useState({ lat: 20.101060, lng: -98.759131 });
    const route = useRoute();
    const dispatch = useDispatch();
    const Contrato = useSelector(state => state.contratos);
    const numeroContrato = Contrato.contratos.numeroContrato;
    const id_empresa = Contrato.contratos.idEmpresa;
    const [newSucursales, setNewSucursales] = useState([]);
    const [selectedSucursal, setSelectedSucursal] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [address, setAdress] = useState([]);
    let getSucursales;

    const municipio = Contrato.contratos.municipio;
    // Función para obtener las coordenadas del municipio de forma dinámica
    const getMunicipioCoordinates = async (municipio: string) => {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${municipio}&key=AIzaSyB_VpXy3aKTYqrbUS24CftvJ6fGzNSTxKg`);
        const data = await response.json();
        
        if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setCenter({ lat, lng });  // Centrar el mapa en las coordenadas del municipio
            
            // Obtener sucursales cercanas
            const getSucursales = await dispatch(getSucursalesData(lat, lng, "Telecable"));
            console.log("****** GET SUCURSALES ", getSucursales);
    
            // Verificar si hay sucursales y si alguna tiene el nombre que contiene "Telecable"
            if (getSucursales.sucursales && Array.isArray(getSucursales.sucursales)) {
                const telecableSucursales = getSucursales.sucursales.filter(sucursal => 
                    sucursal.name && sucursal.name.includes('Telecable')
                );
    
                // Llamar a addMarkers solo si hay sucursales que contienen "Telecable"
                if (telecableSucursales.length > 0) {
                    addMarkers(telecableSucursales);
                }
            }
        }
    };
    
    const addMarkers = (sucursales) => {
        if (!sucursales || sucursales.length === 0) {
            console.error("No se encontraron sucursales para añadir al mapa.");
            return;
        }
        const newMarkers = sucursales.map((sucursal) => ({
            position: { lat: sucursal.geometry.location.lat, lng: sucursal.geometry.location.lng },
            title: sucursal.name,
        }));
        const newAddress = sucursales.map((sucursal) => ({
            direccion: sucursal.vicinity
        }));
        setMarkers(newMarkers);
        setAdress(newAddress);
    };
    // Llamar a la función cuando el componente se monte o cuando cambie el municipio
    useEffect(() => {
        getMunicipioCoordinates(municipio);
    }, [municipio]);
    

    // Cargar Google Maps API
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyB_VpXy3aKTYqrbUS24CftvJ6fGzNSTxKg' // Reemplaza con tu API Key
    });
    return (
        <View>
            <View style={tw`w-full flex-grow mt-15 mb-10 pl-7 pr-7`}>
                <Text style={tw`text-primaryText text-xl mb-2 text-center`}>Ha finalizado exitosamente su trámite</Text>
                <View style={[tw`mt-4`]}>
                    <Text style={tw`text-primaryText font-500 text-xl text-center mb-4 `}>Número de contrato</Text>
                    <Text style={tw`bg-tabs text-datosText text-2xl text-center py-2 rounded-lg tracking-widest`}>{numeroContrato}</Text>
                </View>
                <View style={[tw`mt-4`]}>
                    <Text style={tw`text-primaryText font-500 text-xl text-center mb-4 `}>Datos de Cobro</Text>
                    <Text style={tw`bg-tabs text-datosText text-xl text-center py-2 rounded-lg pr-15 pl-15 text-center`} multiline={true}>
                        Puede pasar a cualquiera de nuestras sucursales para realizar el pago con el número de contrato.
                    </Text>
                </View>
            </View>
            {/* Mapa de sucursales */}
            <View style={[tw`mt-8`]}>
                {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '600px',
                        }}
                        center={center}  // Centro dinámico del mapa
                        zoom={12}  // Nivel de zoom
                    >
                        {/* Marcadores dinámicos */}
                        {markers.map((marker, index) => (
                            <Marker
                                key={index}
                                position={marker.position}
                                title={marker.title}
                                onClick={() => setSelectedSucursal(marker)}  // Seleccionar la sucursal al hacer clic
                            />
                        ))}

                    </GoogleMap>
                ) : (
                    <Text style={tw`text-center text-primaryText`}>Cargando mapa...</Text>
                )}
                <View style={tw`mt-4 mb-10 ml-4 pr-4`}>
                    {address.map((addr, index) => (
                        <View key={index}>
                            <View style={tw`flex-row`}>
                                <UbicacionIcon fill={'gray'} width={18} height={18} style={tw`mr-3 mt-2 min-w-[18px] min-h-[18px]`} />
                                <Text style={tw`text-datosText text-lg`}>{addr.direccion}</Text>
                            </View>
                            <View style={tw`h-0.1 bg-gray-400 mt-4 mb-4`}></View>
                        </View>
                    ))}
                </View>
            </View>
        </View>
    );
}

