import { contratosAPI } from '../../api/contratosApi';
import { clearSucursales, setSucursales, errorSucursales} from './sucursalesSlice';
import Constants from 'expo-constants'

export const getSucursalesData = (lat:any,  lng:any, keyword:any) => {
    return async( dispatch:any ) => {
        dispatch(clearSucursales());
        const responseData = await contratosAPI.get(`/contratos/pago-sucursal?lat=${lat}&lng=${lng}&keyword=${keyword}`)
        .then(data => {
            if(data.data.Success==="OK"){
                dispatch( setSucursales({"sucursales": data.data.sucursales.results}));
                return {"Success": "OK", "sucursales": data.data.sucursales.results}
            }else{
                dispatch( errorSucursales({
                    errorMessage: data.data.errorMessage
                }));
                return {"Success": "ERROR", "messagePagoReporte": data.data.Message};
            }
        })
        .catch(error => {
            dispatch( errorSucursales({
                errorMessage: error
            }));
           return {"Success": "ERROR", "messagePago": error};
        });

        return responseData;
    }
}