import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import EfectivoIcon from '../../assets/icons/efectivo';
import TarjetaIcon from '../../assets/icons/tarjeta';
import OxxoIcon from '../../assets/icons/oxxo';
import SucIcon from '../../assets/icons/suc';
import Text from '../../components/Text';
import FooterMetodoPago from './FooterMetodoPago';
import tw from '../../styles/twrnc-config';


export default function FormaMetodoPago({ navigation }) {
    const Contrato = useSelector(state => state.contratos);
    // const route = useRoute();
    // const { idEmpresa, codigoActivacion } = route.params;
    const [selectedPago, setSelectedPago] = useState("");
    

    const selectedEfectivo = () => {
        setSelectedPago("Efectivo");
    };
    const selectedTarjeta = () => {
        setSelectedPago("Tarjeta");
    };
    const selectedOxxo = () => {
        setSelectedPago("Oxxo");
    };
    const selectedSucursal = () => {
        setSelectedPago("Sucursal");
    };
    const tipoPago = Contrato.contratos.tiposPago;

    return (
        <>
            <View style={tw`w-full bg-bgGrey flex-grow mt-10 pl-7 pr-7`}>
                {tipoPago.includes('pago_domicilio') && (
                    <View style={tw`pl-1 justify-center mb-10`}>
                        <Pressable
                            style={[
                                tw`flex-row pb-8 pt-8 pl-8 pr-8 rounded-lg shadow-lg`,
                                selectedPago === "Efectivo" ? tw`border border-primary bg-tabs` : tw`bg-white`
                            ]}
                            onPress={selectedEfectivo}
                        >
                            <View style={tw`mr-4 justify-center`}>
                                <EfectivoIcon width={55} height={35} />
                            </View>
                            <View style={tw`flex-1`}>
                                <Text style={tw`text-lg text-primaryText`} numberOfLines={2}>
                                    Pagar en mi domicilio
                                </Text>
                                <Text style={tw`text-sm text-datosText`} numberOfLines={2}>
                                    Uno de nuestros representantes recolectará el dinero
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                )}

                {tipoPago.includes('pago_tarjeta') && (
                    <View style={tw`pl-1 justify-center mb-10`}>
                        <Pressable
                            style={[
                                tw`flex-row pb-10 pt-10 pl-8 pr-8 rounded-lg shadow-lg`,
                                selectedPago === "Tarjeta" ? tw`border border-primary bg-tabs` : tw`bg-white`
                            ]}
                            onPress={selectedTarjeta}
                        >
                            <TarjetaIcon style={tw`mr-4`} width={55} height={35} />
                            <View style={tw`flex-1`}>
                                <Text style={tw`text-lg text-primaryText`} numberOfLines={1} ellipsizeMode="tail">
                                    Pagar con tarjeta
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                )}

                {tipoPago.includes('pago_oxxo') && (
                    <View style={tw`pl-1 justify-center mb-10`}>
                        <Pressable
                            style={[
                                tw`flex-row pb-10 pt-10 pl-8 pr-8 rounded-lg shadow-lg`,
                                selectedPago === "Oxxo" ? tw`border border-primary bg-tabs` : tw`bg-white`
                            ]}
                            onPress={selectedOxxo}
                        >
                            <OxxoIcon style={tw`mr-4`} width={55} height={35} />
                            <View style={tw`flex-1`}>
                                <Text style={tw`text-lg text-primaryText`} numberOfLines={1} ellipsizeMode="tail">
                                    Pagar en Oxxo
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                )}
                {tipoPago.includes('pago_domicilio') && (
                    <View style={tw`pl-1 justify-center mb-10`}>
                        <Pressable
                            style={[
                                tw`flex-row pb-10 pt-10 pl-8 pr-8 rounded-lg shadow-lg`,
                                selectedPago === "Sucursal" ? tw`border border-primary bg-tabs` : tw`bg-white`
                            ]}
                            onPress={selectedSucursal}
                        >
                            <SucIcon style={tw`mr-4`} width={55} height={35} />
                            <View style={tw`flex-1`}>
                                <Text style={tw`text-lg text-primaryText`} numberOfLines={1} ellipsizeMode="tail">
                                    Pagar en Sucursal
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                )}
            </View>
            <FooterMetodoPago navigation={navigation} selectedPago={selectedPago} />
        </>
    );
}
